export default {
  components: {
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  data() {
    return {
      opened: false,
      canShow: true,
    }
  },
  props: {
    icon: {
      type: String,
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    link: {
      type: Object,
    },
  },

  mounted() {},

  beforeDestroy() {},

  methods: {
    toggleCard() {
      this.opened = !this.opened
    },
    focusCard() {
      /*if (!this.opened) {
        this.toggleCard()
      }*/
    },
  },
}
